const baseUrl = 'https://www.ubei.cn/api'
const imOrigin = 'https://www.ubei.cn'

module.exports = {
  envConfig: {
    routerPrefix: "",
    supportModule: baseUrl + '/s',
    coreModule: baseUrl + '/c',
    generalModule: baseUrl + '/g',
    adminModule: baseUrl + '/a',
    m8Module: baseUrl + '/m8',
  },
  redirectUrl: 'https://www.ubei.cn',
  erpUrl: 'https://erp.threesoft.cn',
  imOrigin,
  imUrl: imOrigin + '/chat/message',
};