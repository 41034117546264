import company from "./company";
import home from "./home";
import goods from "./goods";
import cooperator from "./cooperator";
import alliance from "./alliance";
import trading from "./trading";
// import customer from './customer';
// import promoteCenter from './promoteCenter';
// import marketing from './marketing';
import setting from "./setting";
import userCenter from "./userCenter";

export default [
  company,
  home,
  // store,
  goods,
  cooperator,
  alliance,
  trading,
  // customer,
  // promoteCenter,
  // marketing,
  setting,
  userCenter,
];
