export default {
  path: "/alliance",
  name: "合作联盟",
  iCons: "icon-commodity-menu",
  redirect: "/allianceManage",
  component: () => import("@/views/ManageCenter"),
  children: [
    {
      path: "/allianceManage",
      name: "联盟管理",
      meta: {
        keepAlive: false,
        level: 2,
      },
      component: {
        render(h) {
          return h("router-view");
        },
      },
      // component: () => import("@/views/alliance/allianceManage/index.vue"),
      redirect: "/allianceManage/list",
      children: [
        {
          path: "/allianceManage/list",
          name: "联盟列表",
          component: () => import("@/views/alliance/allianceManage/index.vue"),
        },
        {
          path: "/allianceManage/create",
          name: "创建联盟",
          isHide: true, //隐藏不显示
          component: () => import("@/views/alliance/create/index.vue"),
        },
        {
          path: "/allianceManage/detail",
          name: "联盟详情",
          isHide: true,
          component: () => import("@/views/alliance/detail/index.vue"),
        },
      ],
    },
    {
      path: "/create",
      name: "创建联盟",
      isHide: true, //隐藏不显示
      component: () => import("@/views/alliance/create/index.vue"),
    },
    {
      path: "/detail",
      name: "联盟详情",
      isHide: true,
      component: () => import("@/views/alliance/detail/index.vue"),
    },
  ],
};
