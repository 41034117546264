import Vue from 'vue';
import Vuex from 'vuex';
import router from '@/router'
import {
  getShareInfo
} from '@/api/commonService';
import {
  loginApi,
  regApi,
  profileApi,
} from '@/api/login'
import {
  MessageBox
} from "element-ui";
import {
  imUrl
} from "envConfigPath";
import { dateFormatToSecond } from "@/utils/date.js";

Vue.use(Vuex);

export const STATUS = {
  LOGIN: 'LOGIN', // 'LOGIN' 登录框
  REG: 'REG', // 'REG' 注册框
  AUTH: 'AUTH', // 'AUTH' 选择是否去认证
  COMINFO1: 'COMINFO1', // 'COMINFO' 填写公司信息去认证
  COMINFO2: 'COMINFO2', // 'COMINFO2' 填写公司详细信息
  INVITECODE: 'INVITECODE', // 'INVITECODE' 填写邀请码去认证
  NEEDBINDCOMPANY: 'NEEDBINDCOMPANY', // 'NEEDBINDCOMPANY' 从ERP过来,需要绑定公司的账号
  BEENTAKED: 'BEENTAKED', // 'BEENTAKED' 从ERP过来,公司名被占用了
  RESETPASS: 'RESETPASS', // 'RESETPASS' 重置密码
  AUTHBINDPHONE: 'AUTHBINDPHONE', // 'AUTHBINDPHONE' 三方授权绑定手机号
}

let isPostMessageIM = false;

function postMessageIM(params) {
  const iframeDom = document.getElementById('imIframe');
  let data = {};
  if (typeof params === "object") {
    data = {
      uid: params.userId,
      partName: params.name,
      partCode: params.code,
      partId: params.swPartId,
      quality: params.quality,
      property: params.property,
      companyId: params.companyId,
      userId: params.userId,
      date: dateFormatToSecond(new Date()),
      isIframe: true,
      msgState: true,
      timeStamp: new Date().getTime(),
      positionUpToDate: false // 定位到最新消息
    }
  } else {
    data = {
      uid: params,
      isIframe: true,
      msgState: true,
      date: dateFormatToSecond(new Date()),
      timeStamp: new Date().getTime(),
      positionUpToDate: true // 定位到最新消息
    }
  }
  iframeDom.contentWindow.postMessage(data, "*");
}

export default new Vuex.Store({
  state: {
    version: '完整版', //版本切换
    isCollapse: false, //左侧导航菜单展开/收起
    bindComDialogVisible: false,
    shareInfo: {
      image: '',
      link: '',
      title: '',
    },
    imVisible: false,
    imSrc: '',
    profile: {},
    mutilCompaies: [],
    regParams: {},
    cacheComInfo: {},
    authStatus: STATUS.LOGIN
  },
  mutations: {
    setVersion(state, data) {
      state.version = data;
    },
    getCollapse(state, data) {
      state.isCollapse = data;
    },
    setProfile(state, data) {
      //console.log('setProfile', data)

      if (data && data.id) {
        Object.assign(state.profile, data);
        state.profile = {
          ...state.profile,
          data
        }
      } else {
        state.profile = {
        }
      }
    },
    setShareInfo(state, data) {
      Object.assign(state.shareInfo, data);
    },
    setNewAuthStatus(state, data) {
      state.authStatus = data;
    },
    setMutilCompaies(state, data) {
      state.mutilCompaies = data;
    },
    setRegParams(state, data) {
      state.regParams = data;
    },
    setCache(state, data) {
      state.cacheComInfo = data;
    },
    setChatSrc(state, data) {
      state.imSrc = data;
    },
    setIMVisible(state, data) {
      state.imVisible = data;
    },
  },
  actions: {
    async getShareInfo({
      commit
    }) {
      const res = await getShareInfo();
      if (res) {
        commit('setShareInfo', res);
      }
    },
    setChatId({
      commit
    }, params) {
      // 向IM传入参数
      const iframeDom = document.getElementById('imIframe');
      if (iframeDom) {
        iframeDom.onload = function (){
          isPostMessageIM = true;
          postMessageIM(params);
        }
        if (isPostMessageIM) {
          postMessageIM(params);
        }
      }
      commit('setChatSrc', `${imUrl}?isIframe=true`);
      /*if (typeof params === "object") {
        commit('setChatSrc', `${imUrl}?isIframe=true${dealWithImUrlParams(params)}`)
      } else {
        commit('setChatSrc', `${imUrl}?uid=${params}&isIframe=true`)
      }*/
    },
    changeIMVisible({
      commit
    }, isShow) {
      //console.log('setIMVisible')
      // 告诉im我显示隐藏的状态
      const iframeDom = document.getElementById('imIframe');
      if (iframeDom) {
        iframeDom.contentWindow.postMessage({
              name: "setVisible",
              visible: isShow,
            },
            "*"
        );
        commit('setIMVisible', isShow)
      } else {
        commit('setIMVisible', false);
      }
    },
    changeAuthStatus({
      commit
    }, newStatus) {
      commit('setNewAuthStatus', newStatus)
    },
    setProfile({
      commit
    }, newStatus) {
      commit('setProfile', newStatus)
    },
    async login({
      commit
    }, regParams) {
      // router.replace('/company/companyInfo')
      const loginResult = await loginApi(regParams)
      // todo
      // 登录未选择公司并且用户在多个公司
      // if (loginResult && loginResult.code === 'multi-company') {
      //   commit('setMutilCompaies', loginResult.data)
      //   commit('setNewAuthStatus', STATUS.NEEDBINDCOMPANY)
      //   return
      // }
      // const profile = await profileApi()
      // if (!profile) return
      const {
        hasCompany
      } = loginResult
      // 店铺未认证,跳转至认证
      if (!hasCompany) {
        commit('setNewAuthStatus', STATUS.AUTH)
        return
      }
      commit('setProfile', loginResult)
      // 正常登录流程
      router.replace('/home/index')
    },
    async reg({
      commit
    }, regParams) {
      const regResult = await regApi(regParams)
      commit('setRegParams', regParams)
      // 正常登录流程
      const {
        code
      } = regResult
      if (code && code === 'multi-users') {
        const users = regResult.data
        let srt = ''
        users.forEach(item => {
          srt += ` [ ${item.account} ] `
        })
        // 提示
        MessageBox.confirm(`当前手机号已绑定平台账号${srt}你确定还要用该手机号注册一个新的平台账号吗?`, '提示', {
          confirmButtonText: '确定注册',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          regApi({
            ...regParams,
            confirm: true
          }).then(() => {
            commit('setNewAuthStatus', STATUS.AUTH)
          })
        })
      } else {
        const {
          hasCompany
        } = regResult
        // 店铺未认证,跳转至认证
        if (!hasCompany) {
          commit('setNewAuthStatus', STATUS.AUTH)
          return
        }
        commit('setProfile', regResult)
        // 正常登录流程
        router.replace('/company/companyInfo')
      }
    },
    async getProfile({
      commit
    }, regParams) {
      const profile = await profileApi(regParams)
      commit('setProfile', {
        ...profile,
        companyAuthed: profile.companyId > 0
      })
    },
    setRegParams({
      commit
    }, regParams) {
      commit('setRegParams', regParams)
    },
    setCache({
      commit
    }, cache) {
      commit('setCache', cache)
    }
  },
  modules: {},
});