export default {
  path: "/trading",
  name: "交易",
  iCons: "icon-deal-menu",
  redirect: "/trading/offerManage",
  component: () => import("@/views/ManageCenter"),
  children: [
    // {
    //   path: "/trading/bidManage",
    //   name: "报价管理",
    //   isHide: true,
    //   component: () => import("@/views/trading/bidManage/bidManage.vue")
    // },
    {
      path: "/trading/payNoteManage",
      name: "支付单管理",
      isHide: true,
      component: () => import("@/views/trading/payNoteManage/payNoteManage.vue")
    },
    {
      path: "/trading/offerManage",
      name: "询报价管理",
      component: () => import("@/views/trading/offerManage/index.vue")
    },
  ]
};
