<template>
  <div id="app">
    <router-view />
    <div v-if="isChatBox" id="chat-box" :style="imVisible ? 'z-index: 3000' : 'z-index: -3000'">
      <div class="im" v-show="imVisible">
        <div class="layer" @click="hideIm"></div>
        <div class="layer-wrap shadow-2xl border">
          <div class="drag-el" v-drag></div>
          <iframe
            id="imIframe"
            :src="imSrc"
            :width="iframeWidth"
            height="600"
            frameborder="0"
          ></iframe>
        </div>
      </div>
    </div>
    <!--  图片预览  -->
    <ImageViewer
      v-if="showViewer"
      :initial-index="initialIndex"
      :on-close="() => (showViewer = false)"
      :url-list="previewSrcList"
    />
  </div>
</template>
<script>
import qs from "qs";
import { mapState } from "vuex";
import { STATUS } from "./store/index";
import { tokenLogin } from "@/api/commonService";
import { imOrigin } from "envConfigPath";
import { getLocation } from "@/utils/location";
import eventBus from "@/event";
import ImageViewer from "@/components/ImageViewer";

let isDrag = false;
export default {
  data() {
    return {
      isCol: false,
      isChatBox: true,
      showViewer: false,
      previewSrcList: [],
      initialIndex: 0
    };
  },
  components:{ImageViewer},
  computed: {
    ...mapState({
      imVisible: (state) => state.imVisible,
      imSrc: (state) => state.imSrc,
    }),
    iframeWidth() {
      return this.isCol ? 1155 : 875;
    },
  },
  directives: {
    drag: {
      // 指令的定义
      bind: function(el) {
        let odiv = el; //获取当前元素
        el.onmousedown = (e) => {
          //算出鼠标相对元素的位置
          let disX = e.clientX - odiv.offsetLeft;
          let disY = e.clientY - odiv.offsetTop;

          let left = "";
          let top = "";
          let pleft = "";
          let ptop = "";
          document.onmousemove = (e) => {
            let parent = el.parentNode;

            //用鼠标的位置减去鼠标相对元素的位置，得到元素的位置
            pleft = e.clientX - parent.offsetLeft;
            ptop = e.clientY - parent.offsetTop;
            isDrag = true;
            left = e.clientX - disX;
            top = e.clientY - disY;
            var docWidth = document.body.clientWidth; //网页可见宽
            var docHeight = document.body.clientHeight; //网页可见高
            if (top > docHeight - 60) {
              //超下边界(键盘DIV长宽分别为 60px、60px)
              top = docHeight - 60;
            }
            if (top < 0) {
              //超上边界
              top = 0;
            }
            if (left > docWidth - 60) {
              //超右边界
              left = docWidth - 60;
            }
            if (left < 0) {
              //超左边界
              left = 0;
            }
            //绑定元素位置到positionX和positionY上面
            //移动当前元素
            // console.log('鼠标坐标:', e.clientX,e.clientY)
            // console.log(pleft,ptop)
            parent.style.left = e.clientX - odiv.offsetLeft - 163 + "px"; // 350 拖拽块宽度的一半
            parent.style.top = e.clientY - 20 + "px"; // 28 拖拽块高度的一半
          };
          document.onmouseup = (e) => {
            document.onmousemove = null;
            document.onmouseup = null;
            setTimeout(() => {
              isDrag = false;
            }, 100);
          };
        };
      },
    },
  },
  created() {
    // ERP嵌入网站时
    if (window.location.href.indexOf("iframe=erp") > -1) {
      window.IFRAME_ERP = true;
      this.setChatBtnHide();
    }

    this.showImageViewer();
  },
  mounted() {
    getLocation();
    this.init();
    this.$store.dispatch("changeIMVisible", false);
    window.addEventListener("message", this.handleMessage, false);
    const isLoginPage = this.$route.path === "/login";
    if (!isLoginPage) {
      this.openMsg();
      setTimeout(() => {
        this.hideIm();
      }, 200);
    }

    // 登录页进入首页时，IM重新渲染
    eventBus.$on("changeIsChatBox", state => {
      if (state) {
        this.isChatBox = false;
        setTimeout(() => {
          this.isChatBox = true;
        }, 200);
      }
    });
  },
  destroyed() {
    sessionStorage.removeItem("Token");
    sessionStorage.removeItem("customerQueryObj");
  },
  methods: {
    // 全局图片预览
    showImageViewer() {
      eventBus.$on("ShowImageViewer", data => {
        if (data) {
          this.previewSrcList = data.list || [];
          this.initialIndex = data.index || 0;
          this.showViewer = true;
        }
      });
    },
    async init() {
      let loginUrl = decodeURIComponent(location.href);
      let hasToken = loginUrl.indexOf("?token=") > -1 ? true : false;
      let startAuth = loginUrl.indexOf("?start-auth=") > -1 ? true : false;
      if (hasToken) {
        const urlParams = qs.parse(location.search.substring(1));
        let params = {
          // allianceDomain: location.hostname,
          token: urlParams.token,
          type: 1,
        };
        const isGotoGM = location.href.indexOf("goodsManage") > -1;
        const isTrading = location.href.indexOf("trading") > -1;
        const isAllianceGM = location.href.indexOf("allianceManage") > -1;
        const isPartsManage = location.href.indexOf("partsManage") > -1;
        let res = await tokenLogin(params);
        if (res) {
          let oldPath = sessionStorage.getItem("oldPath");
          // ERP iframe 嵌入时，如传入iframe=erp时，进入首页
          if (location.href.indexOf("iframe=erp") > -1) {
            window.SOURCE = "erp";
            oldPath = "";
          }
          let toPath = oldPath ? oldPath : "/home/index";
          // 商品管理
          if (isGotoGM) {
            toPath = "/goodsManage/list";
          }
          // 报价管理
          if (isTrading) {
            toPath = "/trading/offerManage";
          }
          // 联盟管理
          if (isAllianceGM) {
            toPath = "/allianceManage/list";
          }
          // M8上下架
          if (isPartsManage) {
            toPath = "/partsManage";
          }
          this.$store.dispatch("getProfile");
          // this.$store.dispatch('getShareInfo');
          this.$router.replace(toPath);
        }
      } else if (startAuth) {
        const params = qs.parse(location.search.substring(1));
        this.$store.dispatch("changeAuthStatus", STATUS.AUTHBINDPHONE);
        this.$router.replace(
          `/login?register-cache-id=${params["register-cache-id"]}`
        );
      } else {
        this.$store.dispatch("getProfile");
      }
    },
    openMsg() {
      this.$store.dispatch("setChatId", 0);
      this.$store.dispatch("changeIMVisible", true);
    },
    handleMessage(event) {
      // console.log(event);
      if (event.origin === imOrigin) {
        // console.log(event.data);
        // 处理消息
        if (event.data === "hide") {
          this.hideIm();
        }
        if (event.data === "fullscreen") {
          this.toggleFull();
        }
        if (event.data === "exitFullscreen") {
          this.toggleFull();
        }
        if (event.data === "close") {
          this.hideIm();
        }
        if (event.data === "col") {
          this.isCol = !this.isCol;
        }
        // 发送回执给发送方
        // event.source.postMessage('Message received', event.origin);
      }
    },
    hideIm() {
      if (!this.imVisible) return;
      this.$store.dispatch("changeIMVisible", false);
    },
    toggleFull() {
      //获取全屏的元素
      if (!document.fullscreenElement) {
        //进入页面全屏
        document.documentElement.requestFullscreen();
      } else {
        if (document.exitFullscreen) {
          //退出全屏
          document.exitFullscreen();
        }
      }
    },
    // ERP嵌入网站时隐藏客服按钮
    setChatBtnHide() {
      const chatBtn = document.getElementById("chatBtn");
      if (chatBtn) {
        chatBtn.style.display = "none";
      } else {
        setTimeout(() => {
          this.setChatBtnHide();
        }, 500);
      }
    }
  },
};
</script>

<style lang="less">
@import "assets/css/public.css";

#app {
  // font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: PingFangSC-Regular;
  // text-align: center;
  font-size: 14px;
  color: #333333;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: auto;
}
.im {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  .layer {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    // background: rgba(0, 0, 0, 0.5);
    z-index: 99999;
  }
  .layer-wrap {
    position: fixed;
    z-index: 999999;
  }
  iframe {
    z-index: 100000;
  }
}
.drag-el {
  position: absolute;
  left: 323px;
  width: 435px;
  height: 50px;
}

// 解决IM窗口闪烁问题/
#chat-box{
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
  position: fixed;
  animation: chatBoxAnimation 2s;
}

@keyframes chatBoxAnimation {
  0% {
    top: -200%;
    opacity: 0;
  }

  to {
    top: 0;
    opacity: 1;
  }
}
</style>
