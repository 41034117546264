export function randomCode(length) {
  let codeArr = [
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "h",
    "i",
    "j",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "q",
    "r",
    "s",
    "t",
    "u",
    "v",
    "w",
    "x",
    "y",
    "z",
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "_",
    "-",
    "$",
    "%",
    "&",
    "@",
    "+",
    "!"
  ];
  let ranCode = "";
  let codeArrLen = codeArr.length;
  for (var i = 0; i < length; i++) {
    var x = Math.floor(Math.random() * codeArrLen);
    ranCode += codeArr[x];
  }
  return ranCode;
}

export function formatDate(fmt, date) {
  if (typeof date === "number") {
    date = new Date(date);
  }
  let ret;
  const opt = {
    "Y+": date.getFullYear().toString(), // 年
    "m+": (date.getMonth() + 1).toString(), // 月
    "d+": date.getDate().toString(), // 日
    "H+": date.getHours().toString(), // 时
    "M+": date.getMinutes().toString(), // 分
    "S+": date.getSeconds().toString() // 秒
  };
  for (let k in opt) {
    ret = new RegExp("(" + k + ")").exec(fmt);
    if (ret) {
      fmt = fmt.replace(
        ret[1],
        ret[1].length == 1 ? opt[k] : opt[k].padStart(ret[1].length, "0")
      );
    }
  }
  return fmt;
}

export function downloadBlob(file, fileName = "excel.xlsx") {
  if (!file) return;
  const url = window.URL.createObjectURL(new Blob([file]));
  const link = document.createElement("a");
  link.style.display = "none";
  link.href = url;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export function base64toFile (dataurl, filename = 'file') {
  let arr = dataurl.split(",");
  let mime = arr[0].match(/:(.*?);/)[1];
  let suffix = mime.split("/")[1];
  let bstr = window.atob(arr[1]);
  let n = bstr.length;
  let u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], `${filename}.${suffix}`, {
    type: mime
  });
}

export function APPScrollTop (distance  = 0) {
  document.getElementById("app").scrollTop = distance;
}

// 数组对象某个属性去重
export function uniqueObject (arr = [], key) {
  let obj = {};
  return arr.reduce((cur, next) => {
    obj[next[key]] ? '' : obj[next[key]] = cur.push(next);
    return cur
  }, [])
}