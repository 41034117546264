import eventBus from "@/event";

// 控制首页定位时请求数据
function getHomePageData(type) {
    if (type === "homePage") {
        eventBus.$emit("getHomePageData", true);
    }
}

export function getLocation(type) {
    AMapLoader.load({
        key: "b49c61222750b095f7c2ad9d9cd7a567", // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: ["AMap.Geolocation"] // 需要使用的的插件列表，如比例尺'AMap.Scale'等
    }).then(AMap => {
        //   console.log(AMap);
        AMap.plugin("AMap.Geolocation", () => {
            const geo = new AMap.Geolocation({
                enableHighAccuracy: true, // 是否使用高精度定位，默认:true
                timeout: 0, // 超过10秒后停止定位，默认：无穷大
                maximumAge: 0, // 定位结果缓存0毫秒，默认：0
                noIpLocate: 0, // 是否禁止使用IP定位，默认值为0，可选值0-3  0: 可以使用IP定位    1: 手机设备禁止使用IP定位  2: PC上禁止使用IP定位  3: 所有终端禁止使用IP定位
                extensions: "all",
                pName: "Geolocation"
            });
            geo.getCityInfo(onComplete, onError);

            function onComplete(event, data) {
                // data是具体的定位信息
                //   alert("省:" + data.province + "\n市:" + data.city);
                // console.log('data',data)
                const locationLocal = localStorage.getItem("location");
                getHomePageData(type);
                if (locationLocal) return
                let locationStr = JSON.stringify(data);
                localStorage.setItem("location", locationStr);
                getHomePageData(type);
            }

            function onError(data) {
                // 定位出错
                console.log("定位 失败 : ", data?.message);
                getHomePageData(type);
            }
        });
    }).catch(e => {
        console.error(e); //加载错误提示
        getHomePageData(type);
    });
}
