import Vue from "vue";
import VueRouter from "vue-router";
// import homeRouter from "./homeRouter";
// import manageCenterRouter from './manageCenterRouter'
import mainRouters from "./routers";
import {
  envConfig
} from "envConfigPath";
import eventBus from "@/event";

Vue.use(VueRouter);
const originalReplace = VueRouter.prototype.replace;
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch(e => e)
}
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(e => e)
}
const routes = [{
    path: "/",
    name: "/",
    redirect: "/home/index"
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/index.vue')
  },
  {
    path: "/redirect/:path",
    name: "Redirect",
    component: () => import("@/views/redirect.vue")
  },
  {
    path: "/partsManage",
    name: "PartsManage",
    meta: {
      title: "商品管理"
    },
    component: () =>
      import(
        /* webpackChunkName: "partsManage" */
        "@/views/partsManage/index.vue"
      )
  },
  ...mainRouters
];

const router = new VueRouter({
  mode: "history",
  base: envConfig.routerPrefix,
  routes
});
router.beforeEach((to, from, next) => {
  // 登录页进入首页时，IM重新渲染
  if (to.path === "/home/index" && from.path === "/login") {
    eventBus.$emit("changeIsChatBox", true);
  }
  // let userInfo = sessionStorage.getItem("userInfo");
  // if (!userInfo){
  //   if (to.path != "/error") {
  //     next('/error');
  //   } else {
  //     next();
  //   }
  // } else{
  next();
  // } 
});

export default router;