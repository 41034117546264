import axios from "axios";
import { Message } from "element-ui";
import router from "@/router";
import { envConfig } from "envConfigPath";

// 接口错误时，不提示错误信息的接口
const ERROR_NO_MESSAGE = [
  "/api/s/account/users/token-login",
  "/api/s/account/users/profile"
];

export const SERVICE_MODULE_MAP = {
  supportModule: envConfig.supportModule,
  coreModule: envConfig.coreModule,
  generalModule: envConfig.generalModule,
  adminModule: envConfig.adminModule,
  m8Module: envConfig.m8Module,
};
// 创建axios实例
const service = axios.create({
  baseURL: SERVICE_MODULE_MAP.united,
  timeout: 30000, // 请求超时时间
  withCredentials: true, // 请求为跨域类型时是否在请求中协带cookie
});

// 添加请求拦截器
service.interceptors.request.use(
  (config) => {
    if (config.url != "/files/upload") {
      const locationStr = localStorage.getItem("location");
      if (locationStr) {
        const { province, city } = JSON.parse(locationStr);
        // console.log(config);
        config.params = {
          ...config.params,
          province,
          city,
        };
        if (config.data)
          config.data = {
            province,
            city,
            ...config.data,
          };
      }
    }
    return config;
  },
  (error) => {
    // 对请求错误执行的操作
    return Promise.reject(error);
  }
);
// 添加响应拦截器
service.interceptors.response.use(
  (response) => {
    const { responseType } = response.config;
    const res = response.data;
    if (responseType === "blob") {
      if (res.message) {
        Message({
          message: res.message || "网络错误",
          type: "error",
          duration: 3500,
          showClose: true,
        });
        return Promise.reject(res);
      } else {
        return Promise.resolve(res);
      }
    } else if (res.code === "ok") {
      return Promise.resolve(res.data);
    } else if (res.code === "multi-users") {
      // 登录手机号绑定有多个账号
      return Promise.resolve(res);
    } else if (res.code === "token-err") {
      // 首页不去请求,避免导航至login
      const ignorePath =
        window.location.pathname == "/" ||
        window.location.pathname.indexOf("home") > -1 ||
        window.location.pathname.indexOf("search") > -1;
      if (!ignorePath) {
        router.replace("/login");
      }
      // Message({
      //   message: res.message || '网络错误',
      //   type: "error",
      //   duration: 3500,
      //   showClose: true
      // });
      return Promise.reject(res);
    } else {
      const errorNoMessage = ERROR_NO_MESSAGE.some(item => response.config.url.includes(item));
      if (!errorNoMessage) {
        Message({
          message: res.message || "网络错误",
          type: "error",
          duration: 3500,
          showClose: true,
        });
      }
      return Promise.reject(res);
    }
  },
  (error) => {
    const errorNoMessage = ERROR_NO_MESSAGE.some(item => error.config.url.includes(item));
    if (!errorNoMessage) {
      Message.error(error.message || "网络错误");
    }
    return Promise.reject(error);
  }
);

export default service;
