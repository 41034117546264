export default {
  path: "/userCenter",
  isHide: true,
  redirect: "/userCenter/account",
  component: () => import("@/views/userCenter/index"),
  children: [
    {
      path: "/userCenter/account",
      isHide: true,
      component: () => import("@/views/userCenter/account/index.vue")
    },
    {
      path: "/userCenter/address",
      isHide: true,
      component: () => import("@/views/userCenter/address/index.vue")
    },
    {
      path: "/userCenter/message",
      isHide: true,
      component: () => import("@/views/userCenter/message/index.vue")
    },
    {
      path: "/userCenter/need",
      isHide: true,
      component: () => import("@/views/userCenter/need/index.vue")
    },
    {
      path: "/userCenter/focus",
      isHide: true,
      component: () => import("@/views/userCenter/focus/index.vue")
    },
    {
      path: "/userCenter/collection",
      isHide: true,
      component: () => import("@/views/userCenter/collection/index.vue")
    },
    {
      path: "/userCenter/history",
      isHide: true,
      component: () => import("@/views/userCenter/history/index.vue")
    }
  ]
};
