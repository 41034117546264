export default {
  path: "/cooperator",
  name: "往来单位",
  iCons: "icon-set-menu",
  redirect: "/cooperator/auditList",
  component: () => import("@/views/ManageCenter"),
  children: [
    {
      path: "/cooperator/auditList",
      name: "待审核单位",
      component: () => import("@/views/cooperator/auditList/index.vue"),
    },
    {
      path: "/cooperator/unitList",
      name: "往来单位查询",
      component: () => import("@/views/cooperator/unitList/index.vue"),
    },
    {
      path: "/cooperator/myFans",
      name: "我的粉丝",
      component: () => import("@/views/cooperator/myFans/index.vue"),
    },
  ],
};
