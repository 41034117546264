export default {
  path: "/home",
  name: "首页",
  redirect: "/home/index",
  component: () => import("@/views/home/index.vue"),
  children: [
    {
      path: "/home/index",
      meta: {
        routerhome: true,
        currentView: 0,
      },
      name: "index",
      component: () => import("@/views/home/index/homepage.vue"),
    },
    {
      path: "/home/search",
      name: "搜索",
      component: () => import("@/views/home/search/index.vue"),
    },
    {
      path: "/home/store",
      name: "店铺",
      component: () => import("@/views/home/store/index.vue"),
    },
    {
      path: "/home/good",
      name: "商品",
      component: () => import("@/views/home/good/index.vue"),
    },
    {
      path: "/home/needZone",
      name: "求购专区",
      meta: {
        routerhome: true,
        currentView: 1,
      },
      component: () => import("@/views/home/index/needZone.vue"),
    },
    {
      path: "/home/postEnquiry",
      name: "发布询价",
      meta: {
        routerhome: true,
        currentView: 2,
      },
      component: () => import("@/views/home/index/postEnquiry.vue"),
    },
    {
      path: "/home/noResult",
      name: "查询未得",
      meta: {
        routerhome: true,
        currentView: 3,
      },
      component: () => import("@/views/home/index/noResult.vue"),
    },
    {
      path: "/home/alliancePromotion",
      name: "联盟推广",
      meta: {
        routerhome: true,
        currentView: 4,
      },
      component: () => import("@/views/home/index/alliancePromotion.vue"),
    },
    {
      path: "/home/factory",
      name: "工厂店",
      component: () => import("@/views/home/index/factory.vue"),
    },
    {
      path: "/home/yellowPage",
      name: "汽配黄页",
      component: () => import("@/views/home/index/yellowPage.vue"),
    },
    {
      path: "/home/myEnquiry",
      name: "我的询价单",
      component: () => import("@/views/home/myEnquiry/index.vue"),
    },
  ],
};
