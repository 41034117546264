import request, {
  SERVICE_MODULE_MAP
} from "@/utils/request";

//token登录
export function tokenLogin(data) {
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: "/account/users/token-login",
    method: "post",
    data
  });
}

export function loginOut(params) {
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: "/account/users/logout",
    method: "post",
    data: params
  });
}

export function loginApi(data) {
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: "/account/users/password-login",
    method: "post",
    data: data
  });
}

export function phoneLoginApi(data) {
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: "/account/users/phone-login",
    method: "post",
    data: data
  });
}

export function regApi(data) {
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: "/account/users/register",
    method: "post",
    data
  });
}

export function profileApi() {
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: "/account/users/profile",
    method: "get",
  });
}

export function updateProfileApi(data) {
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: "/account/users/profile",
    method: "post",
    data
  });
}

export function getBaseFn() {
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: "/base",
    method: "get",
  });
}

export function checkAccountsApi(data) {
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: "/account/users/check-accounts",
    method: "post",
    data
  });
}

export function changePassCheckApi(data) {
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: "/account/users/check-phone",
    method: "post",
    data
  });
}

export function changePhoneApi(data) {
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: "/account/users/change-phone",
    method: "post",
    data
  });
}

export function changePassApi(data) {
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: "/account/users/password/reset",
    method: "post",
    data
  });
}

export function codeUrl(params) {
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: "/account/users/wx-login/code-url",
    method: "get",
    params
  });
}
//获取ERP扫码二维码
export function getErpLoginQrcodeUrlApi(params) {
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: "/account/users/erp-login/qrcode-url",
    method: "get",
    params
  });
}
//erp-login绑定用户
export function accountErpLoginBindApi(data) {
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: "/account/users/erp-login/bind",
    method: "post",
    data
  });
}