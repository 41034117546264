export default {
  path: "/company",
  name: "公司",
  iCons: "icon-set-menu",
  redirect: "/company/companyInfo",
  component: () => import("@/views/ManageCenter"),
  children: [{
      path: "/company/companyInfo",
      name: "公司资料",
      component: () => import("@/views/company/companyInfo/index.vue")
    },
    {
      path: "/company/staff",
      name: "员工管理",
      component: () => import("@/views/company/staff/staff.vue")
    },
  ]
};